<template>
    <div class="main">
        <div class="main1">
            <div class="top">
                <van-image class="t1" round :src="avatar" fit="fill" />
                <div class="t2">
                    <div class="t21">{{ nickName }}</div>
                    <div class="t22">ID: {{ userId }}</div>
                </div>
                <div class="line"></div>
                <div class="t3" style="font-size: 25px">"喂喂"虚拟币充值</div>
            </div>
            <div class="tline"></div>

            <!-- <div class="balanceCard">
                <div class="tip">当前余额</div>
                <div class="balance">
                    <div class="logo"></div>
                    <div class="amount">{{ account.avail_amount - account.prepay_amount }}</div>
                </div>
            </div> -->

            

            

            <div style="height: 25px;"></div>

            <div style="font-size: 30px; display: flex; align-self:flex-start; margin-left: 20px; margin-bottom: 20px;">您正在购买：</div>

            <div class="item">
                <div class="left">
                    <div class="logo"></div>
                    <div class="amount">
                        <div class="num">3000</div>
                        <div class="unit">金币</div>
                    </div>
                </div>
            </div>

            <div class="tline" style="margin-top: 30px"></div>

            <div class="item" style="margin-left: 20px; margin-right: 20px; margin-top: 30px;">
                <div>订单号:</div>
                <div>442748022498000906</div>
            </div>
            <div class="item" style="margin-left: 20px; margin-right: 20px; margin-top: 15px;">
                <div>实付款:</div>
                <div style="color: red">¥30.00</div>
            </div>
            <div class="item" style="margin-left: 20px; margin-right: 20px; margin-top: 15px;">
                <div>创建时间:</div>
                <div>2023-05-14 23:13:24</div>
            </div>
            <div class="item" style="margin-left: 20px; margin-right: 20px; margin-top: 15px;">
                <div>支付方式:</div>
                <div class="llogo"></div>
            </div>
            

            <div style="margin: 20px; margin-top: 80px; font-weight: bold">使用说明：购买的虚拟币可用于“喂喂”APP中消费使用，若您正在使用安卓手机，也可以直接在APP内通过支付宝进行购买。</div>

            <div class="toConfirm" style="margin-top: 20px;">立 即 支 付</div>

            <!-- <div class="switch">
                <div v-if="wechatSwitch == 1" :class="type==2? 'wechat_y':'wechat_n'" @click="type=2; initData()">
                    <div class="logo"></div>
                    <div class="txt">微信支付</div>
                </div>
                <div v-if="wechatSwitch == 1" class="line"></div>
                <div :class="type==3? 'alipay_y':'alipay_n'" @click="type=3; initData()">
                    <div class="logo"></div>
                    <div class="txt">支付宝支付</div>
                </div>
            </div> -->

            <!-- <div v-for="(item, index) in list" :key="index" class="item" @click="unifiedOrder(item)">
                <div class="left">
                    <div class="logo"></div>
                    <div class="amount">
                        <div class="num">{{ item.amount }}</div>
                        <div class="unit">金币</div>
                        <div class="giving">{{ item.first_amount>0 ? item.first_desc : (item.activity_amount>0?item.activity_desc:'') }}</div>
                    </div>
                </div>
                <div class="right">￥{{ formatRMB(item.price_rmb) }}</div>
            </div> -->
        </div>
    </div>
</template>

<script>
import { doRequest } from '@/utils/request'
// import { Toast } from 'vant'

export default {
    data() {
        return {
            type: 3, // 2微信 3支付宝

            list: [],
            account: {},

            nickName: '',
            avatar: '',
            userId: '',

            wechatSwitch: 0,
        }
    },
    methods: {
        unifiedOrder(item) {
            doRequest('/recharge/createOrder', {
                product_id: item.id,
            }, this.$route.query).then(res => {
                let url = res.pay_url
                var link = document.createElement('a');
                link.href=url;
                link.rel = 'external'
                link.click();
            })

            // this.$route.query['productId'] = item.id
            // this.$router.push({
            //     path: '/justRecharge',
            //     query: this.$route.query,
            // })
        },
        formatRMB(rmb) {
            return (parseFloat(rmb, 0) / 100.0).toFixed(2)
        },
        initData() {
            doRequest('/recharge/getProducts', {
                type: parseInt(this.type, 0),
            }, this.$route.query).then(res => {
                this.list = res.products
            })

            doRequest('/recharge/switch', {}, 
            this.$route.query).then(res => {
                this.wechatSwitch = res.wechat
            })

            doRequest('/account/detail', {
                currency: 1,
            }, this.$route.query).then(res => {
                this.account = res.detail
            })

            doRequest('/user/getInfo', {
                target_user_id: this.userId,
            }, this.$route.query).then(res => {
                this.nickName = res.user.alias_nick_name
                this.avatar = res.user.alias_avatar
            })
        }
    },
    mounted() {
        this.userId = this.$route.query['userId']
        this.initData()
    }
}
</script>

<style scoped>
.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 375px;
}

.main1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 375px;
}

.top {
    width: 375px;
    height: 70px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border-radius: 10px;
}

.top .t1 {
    width: 47px;
    height: 47px;
    border-radius: 50%;
    background: black;
    margin-left: 10px;
}

.top .t2 {
    height: 47px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px;
}

.top .t2 .t21 {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
}

.top .t2 .t22 {
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
}

.top .line {
    margin-left: 19px;
    width: 1px;
    height: 26px;
    background: #DDDDDD;
}

.top .t3 {
    margin-left: 21px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
}

.tline {
    margin-top: 6px;
    margin-bottom: 17px;
    width: 375px;
    height: 1px;
    background: #DDDDDD;
}

.balanceCard {
    width: 351px;
    height: 140px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background: url('../../assets/my_recharge_card.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.balanceCard .tip {
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 29px;
}

.balanceCard .balance {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
}

.balanceCard .balance .logo {
    width: 27px;
    height: 22px;
    background: url('../../assets/beike.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.balanceCard .balance .amount {
    height: 26px;
    font-size: 33px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 26px;
    margin-left: 9px;
}

.item {
    width: 351px;
    /* height: 60.33px; */
    /* border-bottom: 1px solid #DDDDDD; */

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.item .left {
    display: flex;
    align-items: center;
}

.item .left .logo {
    width: 40px;
    height: 40px;
    background: url('../../assets/beike.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.item .left .amount {
    margin-left: 6px;
    display: flex;
    align-items: flex-end;
}

.item .left .amount .num {
    margin-left: 6px;
    height: 13px;
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 13px;
}

.item .left .amount .unit {
    height: 10px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 10px;
    margin-left: 6px;
}

.item .left .amount .giving {
    height: 10px;
    font-size: 10px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FDC340;
    line-height: 10px;
    margin-left: 6px;
}

.item .right {
    height: 13px;
    font-size: 17px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 13px;
}

.switch {
    display: flex;
    align-items: center;
    width: 351px;
    margin-top: 225px;
}

.switch .wechat_y {
    display: flex;
    align-items: center;
}

.switch .wechat_y .logo {
    background: url('../../assets/wechat_y.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 18px;
    height: 18px;
}

.switch .wechat_y .txt {
    height: 14px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    line-height: 14px;
    margin-left: 4px;
}

.switch .wechat_n {
    display: flex;
    align-items: center;
}

.switch .wechat_n .logo {
    background: url('../../assets/wechat_n.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 15px;
    height: 15px;
}

.switch .wechat_n .txt {
    line-height: 15px;
    height: 15px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-left: 7px;
}

.switch .line {
    width: 1px;
    height: 10px;
    border: 1px solid #E2E2E2;
    margin-left: 12px;
}

.switch .alipay_y {
    display: flex;
    align-items: center;
    margin-left: 12px;
}

.switch .alipay_y .logo {
    background: url('../../assets/alipay_y.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 18px;
    height: 18px;
}

.llogo {
    background: url('../../assets/alipay_y.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 18px;
    height: 18px;
}

.switch .alipay_y .txt {
    height: 14px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    line-height: 14px;
    margin-left: 4px;
}

.switch .alipay_n {
    display: flex;
    align-items: center;
    margin-left: 12px;
}

.switch .alipay_n .logo {
    background: url('../../assets/alipay_n.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 15px;
    height: 15px;
}

.switch .alipay_n .txt {
    line-height: 15px;
    height: 15px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-left: 7px;
}

.toConfirm {
    width: 122px;
    height: 45px;
    background: linear-gradient(-90deg, #F53760 0%, #FB6F8D 100%);
    border-radius: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 19px;
    font-size: 19px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
}
</style>